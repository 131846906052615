import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

import { PermissionsModule } from '@shure/cloud/shared/permissions/feature-permissions';
import { SidenavLayoutModule } from '@shure/cloud/shared/ui/components';

import { AppShellComponent } from './app-shell.component';

@NgModule({
	declarations: [AppShellComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatListModule,
		MatSnackBarModule,
		MatTooltipModule,
		RouterModule,
		TranslocoModule,
		FlexLayoutModule,
		SidenavLayoutModule,
		PermissionsModule,
		FormsModule
	],
	providers: []
})
export class AppShellModule {}
