/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
	// Disable console logging in production
	if (window) {
		window.console.log = () => {};
		window.console.warn = () => {};
		window.console.debug = () => {};
		window.console.info = () => {};
	}
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	// eslint-disable-next-line no-console
	.catch((err) => console.error(err));
