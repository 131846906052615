import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { LanguagesService } from '@shure/shared/angular/utils/i18n';

@Component({
	selector: 'sh-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
	public appVersion!: string;

	private destroy$: Subject<void> = new Subject<void>();
	constructor(
		private readonly languageService: LanguagesService,
		//public permissionsService: PermissionsService,
		private metaTagService: Meta,
		public oktaIntf: OktaInterfaceService
	) {}

	public ngOnInit(): void {
		// this.organizationStore
		// 	.getVersion()
		// 	.pipe(takeUntil(this.destroy$))
		// 	.subscribe((val: string) => {
		// 		this.appVersion = val;
		// 	});
		this.languageService.getPreferredCultureCode();
		this.metaTagService.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
	}

	/**
	 *  This will be called when the logout event is emitted,
	 *  and the user will be signed out of the application.
	 */
	public async logout(): Promise<void> {
		await this.oktaIntf.signOut();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
