<sh-sidenav-layout *transloco="let t">
	<sh-sidenav-layout-sidenav>
		<mat-nav-list (click)="closeSidenav()">
			<mat-list-item
				[routerLink]="'account'"
				class="sh-menu-account-item"
				routerLinkActive="mat-menu-item-active"
			>
				<div>
					<mat-icon
						matListIcon
						fontSet="prism-mat"
						fontIcon="account_circle"
						role="shure"
						id="sh-home-user-account-icon"
					>
					</mat-icon>
					<p
						matTooltip="{{ userName$ | async }}"
						id="sh-home-first-name-last-name"
						role="shure"
						class="sh-ellipsis"
					>
						{{ userName$ | async }}
					</p>
					<div
						matTooltip="{{ userEmail$ | async }}"
						class="sh-account-email mat-small"
						id="sh-home-email"
						role="shure"
					>
						{{ userEmail$ | async }}
					</div>
				</div>
			</mat-list-item>
			<mat-list-item [routerLink]="['organizations']" routerLinkActive="mat-menu-item-active">
				<mat-icon
					matListIcon
					aria-label="Organizations"
					fontSet="prism-mat"
					fontIcon="business"
					id="sh-home-organizations-icon"
				>
				</mat-icon>
				<p matLine class="mat-list-text" id="sh-home-organizations">
					{{ t('cloud.shure-associate.organizations.organizations') }}
				</p>
			</mat-list-item>
			<!--<mat-list-item
				[routerLink]="['roles']"
				[shPermissions]="'viewRoles'"
				shPermissionsRemove
				routerLinkActive="mat-menu-item-active"
			>
				<mat-icon matListIcon aria-label="roles" fontSet="prism-mat" fontIcon="group" id="sh-home-roles-icon">
				</mat-icon>
				<p matLine id="sh-home-roles">{{ t('roles') }}</p>
			</mat-list-item>-->
			<mat-list-item
				[routerLink]="['roles-permissions']"
				[shPermissions]="'viewRoles'"
				shPermissionsRemove
				routerLinkActive="mat-menu-item-active"
			>
				<mat-icon
					matListIcon
					aria-label="Roles and Permissions"
					fontSet="prism-mat"
					fontIcon="group"
					id="sh-home-roles-permissions-icon"
				>
				</mat-icon>
				<p matLine class="mat-list-text" id="sh-home-roles-permissions">
					{{ t('cloud.shure-associate.roles-permissions.roles-permissions') }}
				</p>
			</mat-list-item>
			<mat-list-item
				[routerLink]="['auditlogs']"
				[shPermissions]="'viewAuditLogs'"
				shPermissionsRemove
				routerLinkActive="mat-menu-item-active"
			>
				<mat-icon
					matListIcon
					aria-label="auditlogs"
					fontSet="prism-mat"
					fontIcon="group"
					id="sh-home-roles-icon"
				>
				</mat-icon>
				<p matLine class="mat-list-text" id="sh-home-logs">
					{{ t('cloud.shure-associate.audit-logs.audit-logs') }}
				</p>
			</mat-list-item>
			<mat-list-item
				[routerLink]="['terms-and-conditions']"
				[shPermissions]="'viewOrganizations'"
				shPermissionsRemove
				routerLinkActive="mat-menu-item-active"
			>
				<mat-icon
					matListIcon
					aria-label="terms-and-conditions"
					fontSet="prism-mat"
					fontIcon="group"
					id="sh-home-tc-icon"
				>
				</mat-icon>
				<p matLine class="mat-list-text" id="sh-home-tc">
					{{ t('cloud.shure-associate.terms-and-conditions.terms-and-conditions') }}
				</p>
			</mat-list-item>
			<mat-list-item
				[routerLink]="['rfscan/files']"
				[shPermissions]="'viewRFSCAN'"
				shPermissionsRemove
				routerLinkActive="mat-menu-item-active"
				(click)="rfScanMenuClicked = !rfScanMenuClicked ? true : false"
			>
				<mat-icon matListIcon aria-label="rfscan" fontSet="prism-mat" fontIcon="group" id="sh-home-roles-icon">
				</mat-icon>
				<p matLine class="mat-list-text" id="sh-app-shell-p-rfscan">
					{{ t('cloud.shure-associate.rfscan.rf-scan') }}
				</p>
				<mat-icon fontSet="prism-mat" fontIcon="keyboard_arrow_up" *ngIf="rfScanMenuClicked"> </mat-icon>
				<mat-icon fontSet="prism-mat" fontIcon="keyboard_arrow_down" *ngIf="!rfScanMenuClicked"> </mat-icon>
			</mat-list-item>
			<mat-list-item
				*ngIf="rfScanMenuClicked"
				[routerLink]="['rfscan/files']"
				[shPermissions]="'viewRFSCAN'"
				shPermissionsRemove
				routerLinkActive="mat-menu-item-active"
			>
				<p matLine class="mat-list-text" id="sh-app-shell-p-scan-files" class="files">
					{{ t('cloud.shure-associate.rfscan.scan-files') }}
				</p>
			</mat-list-item>
			<mat-list-item
				*ngIf="rfScanMenuClicked"
				[routerLink]="['rfscan/maintenance']"
				[shPermissions]="'viewMaintenanceMode'"
				shPermissionsRemove
				routerLinkActive="mat-menu-item-active"
			>
				<p matLine class="mat-list-text" id="sh-app-shell-p-maintenance-mode" class="files">
					{{ t('cloud.shure-associate.rfscan.maintenance-mode') }}
				</p>
			</mat-list-item>
			<mat-list-item
				*ngIf="rfScanMenuClicked"
				[routerLink]="['rfscan/configurations']"
				[shPermissions]="'viewMaintenanceMode'"
				shPermissionsRemove
				routerLinkActive="mat-menu-item-active"
			>
				<p matLine class="mat-list-text" id="sh-app-shell-p-configurations" class="files">
					{{ t('cloud.shure-associate.rfscan.configurations') }}
				</p>
			</mat-list-item>
		</mat-nav-list>
	</sh-sidenav-layout-sidenav>
	<sh-sidenav-layout-content>
		<router-outlet></router-outlet>
	</sh-sidenav-layout-content>
</sh-sidenav-layout>
