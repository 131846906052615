import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { OktaCallbackComponent, OktaAuthModule, OktaAuthGuard } from '@okta/okta-angular';

import { oktaSessionGuard } from '@shure/cloud/shared/okta/data-access';
import { SignInComponent, SigninGuard } from '@shure/cloud/shared/okta/feature-okta-signin';
import { PermissionsGuard } from '@shure/cloud/shared/permissions/feature-permissions';

import { AppShellComponent, AppShellModule } from '../components/app-shell';

const appRoutes: Route[] = [
	{
		path: 'signin',
		component: SignInComponent,
		canActivate: [SigninGuard]
	},
	{
		path: '',
		component: AppShellComponent,
		children: [
			{
				path: '',
				redirectTo: 'account',
				pathMatch: 'full'
			},
			{
				path: 'account',
				loadComponent: () =>
					import('@shure/cloud/shure-associate/account-admin/feature-account-admin').then(
						(m) => m.AccountAdminMainComponent
					),
				data: {
					appName: 'AssociatePortal'
				},
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard]
			},
			{
				path: 'organizations',
				loadChildren: () =>
					import('@shure/cloud/shure-associate/organizations/feature-organizations').then(
						(m) => m.OrganizationsModule
					),
				data: {
					permissions: 'viewOrganizations',
					appName: 'AssociatePortal'
				},
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard]
			},
			// {
			// 	path: 'roles',
			// 	loadChildren: () => import('@shure/cloud-apps/roles/feature-roles-admin').then((m) => m.RolesModule),
			// 	data: {
			// 		permissions: 'viewRoles'
			// 	},
			// 	canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard]
			// },
			{
				path: 'auditlogs',
				loadChildren: () =>
					import('@shure/cloud/shure-associate/audit-logs/feature-audit-logs').then((m) => m.AuditLogsModule),
				data: {
					permissions: 'viewAuditLogs',
					appName: 'AssociatePortal'
				},
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard]
			},
			{
				path: 'terms-and-conditions',
				loadChildren: () =>
					import('@shure/cloud/shure-associate/feature-terms-and-conditions').then(
						(m) => m.TermsAndConditionsModule
					),
				data: {
					permissions: 'viewOrganizations',
					appName: 'AssociatePortal'
				},
				canActivate: [oktaSessionGuard, PermissionsGuard]
			},
			{
				path: 'roles-permissions',
				loadChildren: () =>
					import('@shure/cloud/shure-associate/roles-permissions/feature-roles-permissions').then(
						(m) => m.RolesPermissionsModule
					),
				data: {
					permissions: 'viewRoles',
					appName: 'AssociatePortal'
				},
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard]
			},
			{
				path: 'rfscan',
				loadChildren: () =>
					import('@shure/cloud/shure-associate/rfscan/feature-rfscan').then((m) => m.RfscanModule),
				data: {
					permissions: 'viewRFSCAN',
					appName: 'AssociatePortal'
				},
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard]
			}
		]
	},
	{
		path: 'signin/callback',
		component: OktaCallbackComponent
	},
	{
		path: '**',
		redirectTo: 'signin',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [
		/**
		 * In order to navigate from electron to sub routes e.g. '/room/xxx-xxx' hashing has
		 * to be enabled.
		 */
		RouterModule.forRoot(appRoutes, {
			initialNavigation: 'enabledBlocking',
			useHash: true
		}),
		OktaAuthModule,
		AppShellModule
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
