import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Observable, Subject } from 'rxjs';
//import { takeUntil } from 'rxjs/operators';

//import { OrganizationsStoreService } from '@shure/cloud-apps/organizations/store';
import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { SidenavLayoutStateFacade } from '@shure/cloud/shared/ui/components';

@Component({
	selector: 'sh-app-shell',
	styleUrls: ['./app-shell.component.scss'],
	templateUrl: 'app-shell.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppShellComponent implements OnInit, /*AfterViewInit,*/ OnDestroy {
	public isOpen$: Observable<boolean> | undefined;
	public userEmail$!: Observable<string>;
	public userName$!: Observable<string>;
	public isMenuItemsDisable = true;
	public rfScanMenuClicked = false;

	private readonly destroy$ = new Subject<void>();

	constructor(
		private layoutStateFacade: SidenavLayoutStateFacade,
		private media: MediaObserver,
		private oktaIntfService: OktaInterfaceService
		//private organizationStore: OrganizationsStoreService
	) {
		//subscribed to getSelectedOrganization$ observable to get latest updates on organization selection
		// this.organizationStore
		// 	.getSelectedOrganization$()
		// 	.pipe(takeUntil(this.destroy$))
		// 	.subscribe((orgData) => {
		// 		this.isMenuItemsDisable = !orgData.isOrgSelected;
		// 	});
	}

	public ngOnInit(): void {
		this.isOpen$ = this.layoutStateFacade.isOpen$;
		this.userEmail$ = this.oktaIntfService.getUserEmail$();
		this.userName$ = this.oktaIntfService.getUsername$();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public closeSidenav(): void {
		if (this.media.isActive(['xs', 'sm'])) {
			this.layoutStateFacade.close();
		}
	}

	public openSideNav(): void {
		this.layoutStateFacade.open();
	}
}
