import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatSnackBarConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoService } from '@jsverse/transloco';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

import { OktaModule } from '@shure/cloud/shared/okta/data-access';
import { PermissionsApiModule } from '@shure/cloud/shared/permissions/data-access';
import { OrganizationsStoreModule } from '@shure/cloud/shared/services/organizations-store-service';
import { TopRibbonModule } from '@shure/cloud/shared/ui/components';
import { UsersApiModule } from '@shure/cloud/shared/users/data-access';
import { APP_ENVIRONMENT } from '@shure/cloud/shared/utils/config';
import { ApiBaseurlInterceptor, ApiUrlConfig, AuthTokenInterceptor, HttpModule } from '@shure/cloud/shared/utils/http';
import { InactivityModule } from '@shure/cloud/shared/utils/inactivity';
import { AuditLogsApiModule } from '@shure/cloud/shure-associate/audit-logs/data-access';
import { RfscanApiModule } from '@shure/cloud/shure-associate/rfscan/data-access';
// import { AuditLogsApiModule } from '@shure/cloud-apps/audit-logs/data-access';
//import { AdminRfscanFilesApiModule } from 'libs/cloud-apps/rfscan/data-access/src';
// import { GroupsApiModule } from '@shure/cloud-apps/groups/data-access';
// import { OrganizationsStoreModule } from '@shure/cloud-apps/organizations/store';
// import { PermissionsApiModule } from '@shure/cloud-apps/permissions/data-access';
// import { RolesApiModule } from '@shure/cloud-apps/roles/data-access';
// import { RolesStoreModule } from '@shure/cloud-apps/roles/store';
//import { CloudLoggerSink } from '@shure/cloud-apps/shared';
// import { UsersApiModule } from '@shure/cloud-apps/users/data-access';
//import { ApiBaseurlInterceptor, ApiUrlConfig, AuthTokenInterceptor, HttpModule } from '@shure/shared/angular/http';
import { TranslocoRootModule, translocoInitializer } from '@shure/shared/angular/utils/i18n';
import { ConsoleLoggerSink, LoggingModule, StaticLoggingConfigProvider } from '@shure/shared/angular/utils/logging';

//import { MaterialIconsModule } from '../components/material-icons.module';
import { environment } from '../environments/environment';
import { MaterialIconsModule } from '../modules/material-icons.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

const appBaseURL = window.location.origin;

const oktaConfig: OktaAuthOptions = {
	issuer: 'https://' + environment.authorization.oktaDomain + environment.authorization.authServerPath,
	clientId: environment.authorization.clientId,
	redirectUri: `${appBaseURL}/signin/callback`,
	scopes: environment.authorization.scopes,
	logoutUrl: `${appBaseURL}/signin`,
	pkce: true,
	tokenManager: {
		autoRenew: true,
		syncStorage: true
	}
};

const oktaAuth = new OktaAuth(oktaConfig);

const apiUrlConfig: ApiUrlConfig = {
	apiBaseUrl: environment.orgs?.orgsApiUrl,
	usersApiUrl: environment.orgs?.usersApiUrl
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		MaterialIconsModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		OktaAuthModule,
		TopRibbonModule,
		NgIdleKeepaliveModule.forRoot(),
		InactivityModule.forRoot(),
		OktaModule.forRoot(oktaAuth, '/signin'),
		LoggingModule.forRoot(
			environment.remoteLogging ? [ConsoleLoggerSink] : [ConsoleLoggerSink],
			{ base: { default: environment.logLevel } },
			StaticLoggingConfigProvider
		),
		TranslocoRootModule.forRoot(false),
		HttpClientModule,
		OrganizationsStoreModule,
		// RolesStoreModule,
		// GroupsApiModule.forRoot({ rootUrl: environment.orgs?.groupsApiUrl }),
		// RolesApiModule.forRoot({ rootUrl: environment.orgs?.rolesApiUrl }),
		PermissionsApiModule.forRoot({ rootUrl: environment.orgs?.pemissionsApiUrl }),
		RfscanApiModule.forRoot({ rootUrl: environment.rfscan?.filesApiUrl }),
		AuditLogsApiModule.forRoot({ rootUrl: environment.orgs?.auditLogsApiUrl }),
		UsersApiModule.forRoot({ rootUrl: environment.orgs?.usersApiUrl }),
		HttpModule.forRoot(apiUrlConfig, [
			{
				provide: HTTP_INTERCEPTORS,
				useClass: ApiBaseurlInterceptor,
				multi: true
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: AuthTokenInterceptor,
				multi: true
			}
		]),
		MatNativeDateModule
	],
	providers: [
		{ provide: APP_INITIALIZER, useFactory: translocoInitializer, deps: [TranslocoService], multi: true },
		{ provide: APP_ENVIRONMENT, useValue: environment },
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: <MatFormFieldDefaultOptions>{
				appearance: 'fill',
				subscriptSizing: 'dynamic',
				color: 'accent'
			}
		},
		{
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useValue: <RippleGlobalOptions>{
				disabled: true
			}
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: <MatSnackBarConfig>{
				duration: 8000,
				verticalPosition: 'bottom',
				horizontalPosition: 'center',
				panelClass: ['mat-toolbar', 'mat-primary']
			}
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
