import { LogLevel } from '@shure/shared/angular/utils/logging';

export const environment = {
	production: false,
	remoteLogging: false,
	logLevel: LogLevel.Warning,
	sessionInactivity: {
		idleWarnTime: 10 * 60,
		idleGracePeriod: 5 * 60
	},
	authorization: {
		clientId: '0oa1i4dffmz2h2KeE0h8',
		oktaDomain: 'iam.dev.shure.services',
		oktaDomainExt: 'accountssb.shure.com',
		authServerPath: '/oauth2/default',
		scopes: ['openid', 'profile', 'email', 'offline_access'],
		enableSecondaryIDP: false,
		secondaryIDP: '0oa11drlvpkrHCQQK0h8'
	},
	useClassicEngine: false,
	orgs: {
		groupsApiUrl: 'https://orgs.dev.shure.services/groups',
		licensesApiUrl: 'https://orgs.dev.shure.services/licenses',
		orgsApiUrl: 'https://orgs.dev.shure.services/organizations',
		rolesApiUrl: 'https://orgs.dev.shure.services/roles',
		usersApiUrl: 'https://orgs.dev.shure.services/users',
		pemissionsApiUrl: 'https://orgs.dev.shure.services/permissions',
		auditLogsApiUrl: 'https://orgs.dev.shure.services/audit'
	},
	rfscan: {
		filesApiUrl: 'https://files.dev.shure.services/dev/v1'
	},
	appVersion: '2.1.0.0',
	clientVersion: 'X.X.X.X',
	showOnlyIdp: false,
	applicationClientIds: {
		orgPortalClientId: '0oa15psf9prHkyIyi0h8',
		wwbScanLibClientId: '0oazazi8wazoTIowO0h7'
	},
	appType: 'admin',
	appRoles: []
};
